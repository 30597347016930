
  import router from '@/router'
  import Constains from '@/components/Constains';
  import { defineComponent, onMounted, ref } from 'vue';
  import axios from 'axios';
  import { useCookies } from 'vue3-cookies';
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
  import Tabs from 'ant-design-vue/lib/tabs/src';
  import { notification } from 'ant-design-vue';
  
  export default defineComponent({
    components: {
      ExclamationCircleOutlined,
      Tabs,
    },
  
    setup() {
      const cookies  = useCookies();
      const loading = ref(false)
      let data:any[] = [];
      const dataSource = ref(data);
      const bushoSource = ref<any[]>([]);
      const affiliationSource = ref<any[]>([]);
      const myusercode = ref(0);
      const visible = ref(false)
      const title = ref()
      const createMode = ref(true)
      const passEdit = ref(true)
      const buttonLabel = ref('追加')
      const editData = ref({
        id:0,
        email:null,
        name:null,
        busho:null,
        position:null,
        affiliation:null,
        password:null,
        passcheck:null,
        activate:false,
        authority:false,
        calendar:false,
        sort:null,
      })

      const isAuthority = ref(false)
      const myID = ref(0)

      const columns = [
    {
      title: 'StaffCode',
      name: 'email',
      dataIndex: 'email',
      key: 'email',
      //width: '30%',
    },
    {
      title: '名前',
      name: 'Name',
      dataIndex: 'name',
      //width: '30%',
    },
    {
      title: '部署',
      name: 'busho',
      dataIndex: 'busho',
    },
    {
      title: '所属',
      name: 'affiliation',
      dataIndex: 'affiliation',
    },
    {
      title: '役職',
      name: 'position',
      dataIndex: 'position',
    },
    {
      title:'表示優先度',
      name:'sort',
      dataIndex:'sort',
    },
    {
      title: '有効状態',
      name: 'activate',
      dataIndex: 'activate',
    },
    {
      title: '管理者権限',
      name: 'authority',
      dataIndex: 'authority',
    },
    {
      title: '計画一覧表に表示',
      name: 'calendar',
      dataIndex: 'calendar',
    },
    {
      dataIndex: 'actions',
    },
  ];

  const affiliationColumns = [
           {
                title:'会社名',
                dataIndex: 'label',
                
            },
            {
                title:'操作',
                dataIndex: 'operation',
                width: '30%',
            }
        ]

        const affiliationVisible = ref(false)
        const registAffiliation:any = ref({
          id:0,
          label:null,
        })
  
      onMounted(async () => {
        /*
        var  test:any = [
          {id:8,sort:null},
          {id:7,sort:null},
          {id:5,sort:null},
          {id:6,sort:null},
          {id:1,sort:null},
          {id:2,sort:null},
          {id:3,sort:1},
          {id:4,sort:2}
        ]

        test.sort((a,b)=>{return a.id-b.id})
        test.sort((a,b)=>{
                if(a.sort == null && b.sort == null) return 0
                if(a.sort == null) return -1
                if(b.sort == null) return -1
                return a.sort - b.sort
        })
        console.log('sorttest',test)
        */

        await _reload()
      })

      const _reload =async()=> {
        dataSource.value = []
        bushoSource.value = [{value:0,label:'無登録',}]
        affiliationSource.value = []
        loading.value = true
        var token = cookies.cookies.get('token');

        if(token == null){
          if(Constains.autoLogout){
          //ログインページに飛ばす
          router.push('/login');
          return;
          }
        }

        //ログインユーザー情報を取得
        try{
          let res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
            'Authorization' :'Bearer '+token, }});
        if(res.status == 200){
          let result = res.data;
          if(result.activate == 0) {
            
            if(Constains.autoLogout){
              notification.error({
                message: '非アクティブなアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
          }
          isAuthority.value = result.authority == 1
          myID.value = result.id
         } else if(res.status==400) {
          //ログアウトしてログイン画面に
          if(Constains.autoLogout){
            cookies.cookies.remove('token');
              notification.error({
                message: '無効なアカウントです.',
              })
              setTimeout((()=>{
                router.push('login')
                return;
              }),3000)
            }
         }
        } catch (e) {
          notification.error({
                message: 'ログインに失敗しました.',
              })
         if(Constains.autoLogout) return
        }

        try{
          let res = await axios.get(Constains.tracerbaseURL+'/api/Staffs',{headers:{
              'Authorization' :'Bearer '+token,
            }});
          if(res.status == 200){
            let results = res.data;
            for(let i = 0; i < results.length; i++){
              let result = results[i];
              dataSource.value.push({
                key:result['id'],
                id:result['id'],
                name:result['name'],
                activate:result['activate'] == 1,
                authority:result['authority'] == 1,
                calendar:result['calendar'] == 1,
                busho:result['busho'],
                affiliation:result['affiliation'],
                position:result['position'],
                email:result['email'],
                sort:result['sort'] == 0 ? null:result['sort'] ,
              });
            }

            dataSource.value.sort((a,b)=>{
              return a.id - b.id 
            })

            dataSource.value.sort((a,b)=>{
              if(a.sort == null && b.sort == null) return 0
              if(a.sort == null) return 1
              if(b.sort == null) return -1
              return a.sort - b.sort
            })
          } else {
            //tokenが無効化されているのでログインページに飛ばす
            if(Constains.autoLogout){
              router.push('/login');
              return;
            }
          }

          res = await axios.get(Constains.tracerbaseURL+'/api/Busho',{headers:{
              'Authorization' :'Bearer '+token,
            }});
            console.log("Busho",res)

            if(res.status == 200){
              let results = res.data;
              for(let i = 0; i < results.length; i++){
                let result = results[i];
                bushoSource.value.push({
                  value:result['id'],
                  label:result['label'],
                });
              }
            }

            res = await axios.get(Constains.tracerbaseURL+'/api/Affiliations',{headers:{
              'Authorization' :'Bearer '+token,
            }});
            console.log("Affiliation",res)

            if(res.status == 200){
              let results = res.data;
              for(let i = 0; i < results.length; i++){
                let result = results[i];
                affiliationSource.value.push({
                  value:result['id'],
                  label:result['label'],
                });
              }
            }
        } catch(e){
          console.log("editSaveError:",e)
        }
        loading.value = false
      }
  
      const onFinish = () => {
        console.log("onFinish");
      };
  
      const customRow = (record: any) => {
        return {
          onclick: () => {
            //router.push('/memberdetail/'+record.key);
          },
        }
      }

      const Disable = () => {
        if(createMode.value){
          if(editData.value.email === '' || editData.value.email === null) return true
          if(editData.value.name === '' || editData.value.name === null) return true
          if(editData.value.password === '' || editData.value.password === null) return true
          return editData.value.password !== editData.value.passcheck
        }

        if(passEdit.value){
          if(editData.value.password === '' || editData.value.password === null ) return true
          return editData.value.password != editData.value.passcheck
        }

        if(editData.value.email === '' || editData.value.email === null) return true
        if(editData.value.name === '' || editData.value.name === null) return true

        return false
      }

      const handleOk = async(e:any) => {
        loading.value = true
        try{
          var token = cookies.cookies.get('token');
          if(createMode.value){
            const json = {
              id:0,
              name:editData.value.name,
              email:editData.value.email,
              busho:editData.value.busho == null ? 0: editData.value.busho,
              affiliation: editData.value.affiliation == null ? 0: editData.value.affiliation,
              position:editData.value.position == null ? '' : editData.value.position,
              sort:editData.value.sort == null ? 0 : editData.value.sort,
              pass:editData.value.password,
              activate: editData.value.activate ? 1 : 0,
              authority: editData.value.authority ? 1 : 0,
              calendar: editData.value.calendar ? 1 : 0,
            }
            console.log("新規作成",json)
            const res = await axios.post(Constains.tracerbaseURL+'/api/Staffs',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
            console.log('registStaff:'+res);

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
            } else {
              //エラー表示
            }
          } else {
            const json = {
              id:editData.value.id,
              name:editData.value.name,
              email:editData.value.email,
              busho:editData.value.busho == null ? 0: editData.value.busho,
              affiliation: editData.value.affiliation,
              position:editData.value.position == null ? '' : editData.value.position,
              sort:editData.value.sort == null ? 0 : editData.value.sort,
              pass:editData.value.password == null ? 'string': editData.value.password,
              activate: editData.value.activate ? 1 : 0,
              authority: editData.value.authority ? 1 : 0,
              calendar: editData.value.calendar ? 1 : 0,
              passUpdate: passEdit.value ? 1 : 0,
            }
            console.log("更新",json)
            const res = await axios.put(Constains.tracerbaseURL+'/api/Staffs',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
            console.log('registStaff:'+res);

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
            } else {
              //エラー表示
            }
          }
          
        } catch(e){
          console.log(e)
        }
        loading.value = false
        visible.value = false
      }

      const deleteStaff = async() =>{
        loading.value = true;
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Staffs/'+editData.value.id,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:editData.value.id},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              visible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      const add = () => {
        visible.value = true
        passEdit.value = true
        createMode.value = true
        title.value = '新規追加'
        buttonLabel.value = '追加'

        editData.value.id = 0
        editData.value.email = null
        editData.value.name = null
        editData.value.busho = null
        editData.value.affiliation = null
        editData.value.position = null
        editData.value.sort = null
        editData.value.password = null
        editData.value.passcheck = null
        editData.value.activate = false
        editData.value.authority = false
        editData.value.calendar = false
      }

      const edit = (record:any) => {
        visible.value = true
        passEdit.value = false
        createMode.value = false
        title.value = '編集'
        buttonLabel.value = '更新'

        editData.value.id = record.id
        editData.value.email = record.email
        editData.value.name = record.name
        editData.value.busho = record.busho
        editData.value.affiliation = record.affiliation
        editData.value.position = record.position
        editData.value.sort = record.sort
        editData.value.password = null
        editData.value.passcheck = null
        editData.value.activate = record.activate
        editData.value.authority = record.authority
        editData.value.calendar = record.calendar
      }

      const editPass = (record:any) => {
        visible.value = true
        passEdit.value = true
        createMode.value = false
        title.value = 'パスワード変更'

        editData.value.id = record.id
        editData.value.email = record.email
        editData.value.name = record.name
        editData.value.busho = record.busho
        editData.value.affiliation = record.affiliation
        editData.value.position = record.position
        editData.value.sort = record.sort
        editData.value.password = null
        editData.value.passcheck = null
        editData.value.activate = record.activate
        editData.value.authority = record.authority
        editData.value.calendar = record.calendar
      }
      
      const getBushoLabel = (id:number) => {
        var index = bushoSource.value.findIndex(e=>e.value===id);
        if(index >= 0) return bushoSource.value[index].label
        else return null
      }

      const getAffiliationLabel = (id:number) => {
        var index = affiliationSource.value.findIndex(e=>e.value===id);
        if(index >= 0) return affiliationSource.value[index].label
        else return null
      }

      const createAffiliation = () => {
        registAffiliation.value.id = 0
        registAffiliation.value.label = ''
        affiliationVisible.value = true
      }

      const editAffiliation = (record:any) => {
        registAffiliation.value.id = record.value
        registAffiliation.value.label = record.label
        affiliationVisible.value = true
      }

      const registAffiliationData = async() => {
        loading.value = true
        let json = {
            id:registAffiliation.value.id,
            label:registAffiliation.value.label
        }
        console.log(json)
        var token = cookies.cookies.get('token');
        if(registAffiliation.value.id == 0){
          try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Affiliations',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
            console.log('addAchieve.states:'+res.status);
            console.log('addAchieve.response:'+JSON.stringify(res.data));

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registAffiliation.value.id = 0
              registAffiliation.value.label = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        } else {
          try{
            const res = await axios.put(Constains.tracerbaseURL+'/api/Affiliations/'+registAffiliation.value.id,JSON.stringify(json),
              {headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, }});
            console.log('addAchieve.states:'+res.status);
            console.log('addAchieve.response:'+JSON.stringify(res.data));

            if(res.status == 200||res.status == 201||res.status == 204){
              await _reload()
              registAffiliation.value.id = 0
              registAffiliation.value.label = null
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
        }

        affiliationVisible.value = false;
        loading.value = false;
      }

      const deleteAffiliation = async () => {
        loading.value = true;
        var token = cookies.cookies.get('token');
        try{
            const res = await axios.delete(Constains.tracerbaseURL+'/api/Affiliations/'+registAffiliation.value.id,
              {
                headers:{'Content-Type': 'application/json','Authorization' :'Bearer '+token, },
                data:{id:registAffiliation.value.id},
              });

            if(res.status == 200||res.status == 201||res.status == 204){
              affiliationVisible.value = false
              await _reload()
            } else {
              //エラー表示
            }
          } catch (e){
            console.log(e)
          }
      }

      
  
      return {
        activeKey: ref('1'),
        columns,
        dataSource,
        onFinish,
        onMounted,
        customRow,
        myusercode,
        visible,
        handleOk,
        add,
        title,
        passEdit,
        createMode,
        edit,
        editPass,
        Disable,
        editData,
        loading,
        buttonLabel,
        isAuthority,
        myID,
        bushoSource,
        getBushoLabel,
        affiliationSource,
        getAffiliationLabel,
        affiliationColumns,
        affiliationVisible,
        registAffiliation,
        registAffiliationData,
        createAffiliation,
        editAffiliation,
        deleteAffiliation,
        deleteStaff,
      };
    },
  });
  